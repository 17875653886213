
.rhap_container {
  border-radius: 10px;
}

.rhap_controls-section {
  flex: 0
}

.rhap_play-pause-button {
  color: var(--color-general-ui);
  background-color: transparent;
  border: none;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
}

.rhap_additional-controls {
  display: none;
}

.rhap_time {
  color: var(--color-general-ui);
  font-size: 14px;
}

.rhap_progress-container {
  flex: 5
}

.rhap_progress-filled, .rhap_progress-indicator {
  background-color: var(--color-general-ui);
}

.rhap_volume-controls {
  flex: 1 0 auto
}

.rhap_volume-bar, .rhap_volume-indicator {
  background-color: var(--color-general-ui);
}

.rhap_volume-button {
  color: var(--color-general-ui);
}

.rhap_download-link {
  color: var(--color-general-ui);
}
