@import '../../../../variables';
.container {
  margin-bottom: 40px;
}

.header {
  display: flex;
  align-items: center;
  & > h2 {
    margin-bottom: 0;
  }
}

.body {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
