@import '../../variables';

.title {
  font-size: 14px;
  font-weight: 400;
  margin: 16px 0 8px;
  color: var(--color-text-primary);
}

.errorMessage {
  font-size: 12px;
  position: absolute;
  color: var(--color-text-error);
  margin: 0;
}
