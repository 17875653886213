@import '../../../../variables';

.container{
  margin-bottom: 40px;
}

.header {
  display: flex;
  align-items: center;
  & > h2 {
    margin-bottom: 0;
  }
}

.body {
  margin-top: 40px;
  display: flex;
  gap:40px;
}

.form{
  width: 320px;
}

.paragraph {
  font-size: 14px;
  margin: 12px 0 0;
  color: $spun-pearl-grey;
}

.switchersContainer{
  margin-top: 32px;
  margin-left: -12px;
}
