@import "../../../../variables";
.container {
  border-radius: 10px;
  border: 1px solid var(--color-issabeline-grey);
  width: 100%;
  margin-bottom: 10px;
  min-width: 400px;
  &:hover {
    & i {
      display: block;
    }
  }
}

.header {
  padding: 17px 24px 24px 24px;
  font-weight: 700;
  color: $oxford-blue;
  border-bottom: 2px solid var(--color-issabeline-grey);
  display: flex;
  justify-content: space-between;
}

.body {
  padding: 24px;
}

.deleteTemplate {
  right: 28px;
  top: -16px;
  position: absolute;
  display: none;
  transition: all 0.5s ease;
}

.deleteIcon {
  background: var(--color-white);
  border: 1px solid var(--color-issabeline-grey);
  box-shadow: 0 3px 6px var(--color-issabeline-grey);
  width: 40px;
  height: 40px;
  position: absolute;
  right: -40px;
  display: block;
}
