.profileImage {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.channelIcon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
}
