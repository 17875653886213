.container {
  display: flex;
  gap: 8px;
  &:hover > span {
    visibility: visible;
  }
}

.iconWrap{
  cursor: pointer;
  display: flex;
  align-items: center;
  visibility: hidden;
}
