@import '../../../../variables';

.container {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  padding: 8px 0;
  border-bottom: 1px solid rgba(53, 64, 82, 0.1);
  border-top: 1px solid rgba(53, 64, 82, 0.1);
}

.title {
  font-size: 14px;
  color: var(--color-text-primary);
  font-weight: 700;
}

.button {
  cursor: pointer;
}
