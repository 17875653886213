.gridItem {
  height: 10px;
  width: 100%;
  margin-bottom: 5px;
}

.tooltip {
  border: 1px solid var(--color-issabeline-grey);
  box-sizing: border-box;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  border-radius: 10px;
  z-index: 9999;
  opacity: 1;
  background: var(--color-white);
}
