@import '../../../../variables';

.container {
  padding-top: 24px;
  min-height: 360px;
}
.header{
  display: flex;
  gap: 12px;
  position: relative;
  margin-bottom: 24px;
  justify-content: space-between;
  &::after {
    content: '';
    position: absolute;
    border-bottom: 1px solid var(--color-issabeline-grey);
    width: 100%;
    bottom: 0;
    z-index: -1;
  }
}
.tabs {
  display: flex;
  gap: 12px;
  position: relative;
}
.tab {
  color: var(--color-oxford-blue);
  font-size: 12px;
  font-weight: bold;
  padding: 4px;
  cursor: pointer;
  white-space: nowrap;
}
.activeTab {
  color: var(--color-button-secondary);
  font-size: 12px;
  font-weight: bold;
  padding: 4px;
  cursor: pointer;
  border-bottom: 1px solid var(--color-button-secondary);
  white-space: nowrap;
}
.button {
  color: var(--color-button-secondary);
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  padding: 4px;
  white-space:nowrap;
}
