@import "../../../../variables";

.container {
  height: 100%;
}

.row {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  padding-left: 44px;
}

.link {
  flex-basis: 0;
  flex-grow: 1;
  font-size: 12px;
  color: var(--color-button-primary);
  text-align: center;
  cursor: pointer;
  padding: 0 1px;
}

.atomName {
  flex-basis: 0;
  flex-grow: 1;
  font-size: 12px;
  color: var(--color-text-secondary);
  text-align: center;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
