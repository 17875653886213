@import url(https://fonts.googleapis.com/css?family=Open+Sans);

*,
*::before,
*::after {
  box-sizing: border-box;
}

/*.container, .backdrop, textarea {*/
/*width: 100%;*/
/*}*/
.paramsAnother {
  width: 400px !important;
  margin: 0;
}

.highlights,
textarea {
  padding: 10px;
  font: 14px/23px 'Open Sans', sans-serif;
  letter-spacing: 0px;
}

.container {
  display: flex;
  transform: translateZ(0);
  -webkit-text-size-adjust: none;
}

.backdrop {
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid var(--color-platinum);
  /*border-radius: 10px;*/
  background-color: #fff;
  overflow: auto;
  width: 100%;
  height: 140px;
  pointer-events: none;
  transition: transform 1s;
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
}

textarea {
  display: block;
  position: absolute;
  z-index: 2;
  margin: 0;
  height: 140px;
  border-radius: 10px;
  border: 1px solid var(--color-platinum);
  /*border-radius: 10px;*/
  color: #444;
  background-color: transparent;
  overflow: auto;
  resize: none;
  transition: transform 1s;
  outline: none;
  width: 100%;
}
