.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.chartContainer {
  padding-right: 28px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 28px;
  width: 100%;
  height: 100%;
}

.chartContainerSelected {
  padding-right: 28px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 28px;
  width: calc(100% - 400px);
  height: 100%;
}
