@import '../../../../variables';

.body {
  padding:0 40px 50px;
  height: calc(100vh - 400px);
  overflow: auto;
}

.tab {
  color: $oxford-blue;
  font-size: 14px;
  padding: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.activeTab {
  color: var(--color-button-primary);
  font-size: 14px;
  padding: 4px;
  cursor: pointer;
  border-bottom: 1px solid var(--color-button-primary);
  white-space: nowrap;
}

.tabs {
  margin: 20px 24px 10px;
  display: flex;
  gap: 28px;
  position: relative;
}
