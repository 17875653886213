@import '../../../../variables';

.container {
  width: 360px;
  min-width: 360px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  height: 100%;
}

.header {
  width: 100%;
  height: 130px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
}

.logo {
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 50%;
}

.nameWrap {
  display: flex;
  align-items: center;
}

.titleWrap {
  margin: 0 0 0 24px;
  color: var(--color-white);
  font-weight: 100;
}

.title {
  font-size: 20px;
  margin: 0;
}

.greetingText {
  font-size: 14px;
  margin: 0;
}

.body {
  background-color: var(--color-white);
  width: 100%;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.label {
  color: var(--color-text-secondary);
  font-size: 14px;
  width: 100%;
}

.inputWrap {
  position: relative;
  width: 100%;
}

.input {
  margin-top: 16px;
  border-radius: 10px;
  border: 1px solid $mischka-grey;
  font-size: 14px;
  color: $mischka-grey;
  padding: 12px 24px;
  width: 100%;
}

.button {
  margin-top: 24px;
  padding: 14px 24px;
  border-radius: 10px;
  color: var(--color-white);
  width: fit-content;
  font-size: 14px;
}

.buttonInput {
  position: absolute;
  right: 0;
  top: 15px;
  color: var(--color-white);
  padding: 13px 24px;
  border-radius: 0 10px 10px 0;
  font-size: 14px;
  border: 1px solid;
}

.iconWrap{
  width: 14px;
}
