@import '../../../../variables';

.addButton {
  color: var(--color-button-secondary);
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  margin-top: 8px;
  width: fit-content;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.labels {
  display: flex;
  padding-right: 28px;
  & > span {
    font-size: 12px;
    font-weight: 700;
    width: 50%;
  }
}
