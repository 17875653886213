@import '../../../../variables';

.filterItem {
  height: 28px;
  color: var(--color-text-primary);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  align-items: center;
}

.filterItem:hover {
  background-color: var(--color-hover-overlay-primary);
}

.filterItem > span {
  width: 36px;
  text-align: center;
  color: var(--color-text-on-primary);
}
