@import '../../variables';

.inputTitle {
  font-size: 14px;
  color: var(--color-text-primary);
  font-weight: 400;
  margin: 24px 0 8px;
}

.container {
  width: 100%;
  display: flex;
  margin-bottom: 8px;
}

.option {
  border: 1px solid $ghost-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  color: var(--color-text-secondary);
  flex-grow: 1;
  flex-basis: 0;
  cursor: pointer;
  &:first-child{
    border-radius: 10px 0 0 10px;
  }
  &:last-child{
    border-radius: 0 10px 10px 0;
  }
}

.optionActive{
  border: 1px solid var(--color-button-secondary);
  background-color: var(--color-button-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  color: var(--color-text-on-secondary);
  flex-grow: 1;
  flex-basis: 0;
  cursor: pointer;
  &:first-child{
    border-radius: 10px 0 0 10px;
  }
  &:last-child{
    border-radius: 0 10px 10px 0;
  }
}
