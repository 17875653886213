@import '../../../../variables';

.container {
  position: absolute;
  background-color: var(--color-white);
  padding: 24px 0 0;
  outline: none;
  width: 600px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  max-height: 90%;
}

.header {
  padding: 0 24px;
}

.closeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.title {
  font-weight: bold;
  font-size: 24px;
  color: $oxford-blue;
  margin: 0;
}

.body {
  padding: 24px 40px 50px;
}

.footer {
  border-top: 1px solid $ghost-grey;
  padding: 24px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
