@import '../../../../variables';

.wrap{
  position: relative;
}

.container {
  width: 202px;
  height: 148px;
  background-color: var(--color-white);
  border-radius: 10px;
  border: 1px dashed $mischka-grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.iconWrap {
  width: 50px;
  height: 50px;
  background-color: var(--color-issabeline-grey);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
}

.label {
  margin: 0;
  font-size: 14px;
  color: $oxford-blue;
  font-weight: bold;
  & > span {
    color: var(--color-button-primary);
  }
}

.uploadContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.description{
  font-size: 14px;
  color: $spun-pearl-grey;
}

.errorMessage{
  font-size: 12px;
  position: absolute;
  color: var(--color-text-error);
  margin: 0;
}
