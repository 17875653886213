@import '../../../../variables';

.container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding-top: 18px;
  height: 265px;
}

.inputContainer {
  display: flex;
  gap: 8px;
}

.inputWrap{
  width: 150px;
}
