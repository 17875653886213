@import '../../../../variables';

.container {
  padding: 0 16px;
  overflow: auto;
  height: 100%;
}

.date {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-text-primary);
  margin: 16px 0 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.bottomScrollElem {
  float: left;
  clear: both;
}


.loaderContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
