.daterangepicker td.start-date,
.daterangepicker td.start-date.active {
    background-color: var(--color-button-secondary);
}

.daterangepicker td.in-range {
    background-color: var(--color-hover-overlay-secondary);
}

.daterangepicker button.applyBtn,
.daterangepicker button.applyBtn:hover{
    color: var(--color-text-on-primary);
    background-color: var(--color-button-primary);
    border-color: var(--color-button-primary);
}

/* End Date */
.daterangepicker td.end-date,
.daterangepicker td.end-date.active {
    background-color: var(--color-button-secondary);
}

/* Today (Initial Color) */
.daterangepicker td.today.active {
    background-color: var(--color-button-secondary);
}
