@import "../../../../variables";

.container {
  padding: 40px;
  overflow: scroll;
  height: calc(100vh - 250px);
}

.iconWrap {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-issabeline-grey);
  box-shadow: 0 3px 6px var(--color-issabeline-grey);
  padding: 8px 10px;
  border-radius: 50px;
}

.stepWrap {
  position: relative;
}
