.container {
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;
}

.button {
  cursor: pointer;
  padding: 0 10px;
}

.input {
  height: 32px;
  min-width: 32px;
  width: 32px;
  outline: none;
  border: 1px solid #d7d8df;
  border-radius: 10px;
  color: var(--color-button-primary);
  text-align: center;
}

.inputHolder {
  display: flex;
  align-items: center;
  gap: 8px;
}
