@import '../../../../variables';

.button {
  cursor: pointer;
  color: var(--color-button-primary);
}

.editButton {
  padding: 9px 13px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 160px;
  &:hover {
    background: var(--color-hover-overlay-primary);
  }
}

.deleteButton {
  padding: 9px 13px;
  cursor: pointer;
  font-size: 14px;
  color: var(--color-text-error);
  display: flex;
  align-items: center;
  gap: 12px;
  width: 160px;
  &:hover {
    background: var(--color-hover-overlay-primary);
  }
}

.link {
  color: var(--color-button-primary);
  cursor: pointer;
}
