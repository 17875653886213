@import '../../../../variables';

.filterItem {
  height: 28px;
  color: var(--color-text-primary);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  align-items: center;
  padding: 0 12px;
}

.filterItem:hover {
  background-color: var(--color-hover-overlay-primary);
}

.filterItem > span {
  width: 36px;
  text-align: center;
}

.popover {
  width: 135px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(19, 69, 186, 0.241904);
  padding: 6px 0;
}
