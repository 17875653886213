.input {
  width: 100%;
  height: 32px;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  margin: 8px auto auto;
  border: 1px solid var(--color-issabeline-grey);
  outline-color: var(--color-button-secondary);
  cursor: pointer;
}

.dateRangePickerWrap {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 100%;
  z-index: 10;
  margin-left: auto;
}
