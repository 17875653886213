@import "../../../../variables";

.container {
  padding: 12px 0;
}

.nameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nameWrap {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  display: inline;
}

.nameWrap > span {
  font-weight: 300;
  color: rgba(53, 64, 82, 0.5);
  margin-left: 8px;
}

.score {
  color: var(--color-coral-red);
}

.comment {
  padding-top: 9px;
  font-size: 14px;
  color: rgba(53, 64, 82, 0.7);
}
