.tooltip{
  box-sizing: border-box;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  border-radius: 10px;
  z-index: 9999;
  opacity: 1;
  text-align: start;
  font-weight: normal;
  padding: 10px;
  background-color: var(--color-text-secondary);
}
